@charset "UTF-8";
@import "variables";
@import "mixins";

.m_hoyo_body {
  .inner {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(pc) {
      padding-left: calc(50% - 570px);
      padding-right: calc(50% - 570px);
    }
    .unit {
      margin-top: 120px;
      @include mq(tbl){
        margin-top: 292px;
        display: flex;
      }
      &:first-child {
        margin-top: 90px;
        @include mq(tbl){
          margin-top: 166px;
        }
      }
      &:nth-of-type(2n){
        flex-direction: row-reverse;
        .text {
          @include mq(tbl){
            padding: 0 9% 0 13%;
          }
        }
      }
      .image {
        text-align: center;
        @include mq(tbl){
          width: 42%;
        }
        img {
          max-width: 480px;
          width: 100%;
        }
      }
      .text {
        padding-top: 30px;
        @include mq(tbl){
          padding-top: 0;
          width: 58%;
          padding: 0 13% 0 9%;
        }
        h2 {
          @include title-default;
          text-align: center;
          margin-top: 30px;
          @include mq(tbl){
            margin-top: 0;
            text-align: left;
          }
        }
        p {
          @include text-default;
        }
      }
    }
  }
}
.m_hoyo_schedule {
  margin-top: 80px;
  @include mq(tbl){
    margin-top: 212px;
  }
  .m_title + & {
    margin-top: 0;
    @include mq(tbl){
      margin-top: 0;
      padding-top: 180px;
    }
  }
  .inner {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    @include mq(tbl) {
      padding-left: calc(50% - 440px);
      padding-right: calc(50% - 440px);
      text-align: left;
      position: relative;
    }
    h2 {
      @include fs(16,30);
      letter-spacing: .1em;
      border-bottom: 1px solid #ccc;
      padding-bottom: 32px;
      @include mq(tbl){
        @include fs(19,36);
        padding-bottom: 42px;
      }
    }
    h3 {
      margin-top: 42px;
      @include fs(16,30);
      letter-spacing: .1em;
      padding-bottom: 32px;
      @include mq(tbl){
        @include fs(19,36);
        margin-top: 50px;
        padding-bottom: 0;
        position: absolute;
      }
    }
    .list {
      @include mq(tbl){
        margin-left: 27%;
      }
      .unit {
        border-bottom: 1px solid #ccc;
        padding: 26px 0 22px;
        @include mq(tbl){
          padding: 28px 0 26px 30px;
        }
        &:last-child {
          border-bottom: 0;
        }
        dl {
          @include fs(16,30);
          letter-spacing: .1em;
          @include mq(tbl){
            @include fs(19,36);
            display: flex;
          }
          dt {
            @include mq(tbl){
              width: 250px;
              padding-right: 25px;
            }
          }
          dd {
            margin-top: 4px;
            @include mq(tbl){
              margin-top: 0;
              flex: 1;
            }
          }
        }
        .place {
          margin-top: 8px;
          @include fs(10,17);
          letter-spacing: .1em;
          font-family: $bbb;
          color: $main-light;
          @include mq(tbl){
            @include fs(12,20);
          }
          a {
            text-decoration: underline;
            @include mq(tbl){
              transition: color $transition-speed-fast $bezier;
              &:hover {
                color: $sub;
              }
            }
          }
        }
      }
    }
  }
}
.m_hoyo_nav {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
  @include mq(tbl) {
    padding-left: calc(50% - 440px);
    padding-right: calc(50% - 440px);
  }
  .inner {
    border-top: 1px solid #ccc;
    padding-top: 6px;
    position: relative;
    @include mq(tbl) {
      padding: 44px 17px 0;
    }
    .prev {
      margin: 30px 0 0 0;
      @include prev;
      @include mq(tbl) {
        margin-top: 0;
      }
    }
    .next {
      margin: 30px 0 0 0;
      @include next;
      @include mq(tbl) {
        margin-top: 0;
      }
    }
  }
}