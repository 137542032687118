@charset "UTF-8";
@import "variables";
@import "mixins";

.m_about_lead {
  margin-top: 90px;
  @include mq(tbl){
    margin-top: 105px;
  }
  .inner {
    @include container(10px);
    text-align: center;
    p {
      @include fs(23,48);
      letter-spacing: .2em;
      @include mq(tbl){
        @include fs(28,57);
      }
      br {
        @include sp_pc;
      }
    }
  }
}
.m_about_body {
  margin-top: 90px;
  @include mq(tbl){
    margin-top: 105px;
  }
  .inner {
    @include container;
    .unit {
      margin: 110px auto 0;
      max-width: 640px;
      @include mq(tbl){
        margin-top: 165px;
      }
      &:first-child {
        @include mq(tbl){
          margin-top: 130px;
        }
      }
      .image {
        text-align: center;
        img {
          &.about_01 {
            width: 225px;
            @include mq(tbl){
              width: 273px;
            }
          }
          &.about_02 {
            width: 190px;
            @include mq(tbl){
              width: 218px;
            }
          }
          &.about_03 {
            width: 220px;
            @include mq(tbl){
              width: 253px;
            }
          }
        }
      }
      h1 {
        @include title-default;
        text-align: center;
        margin-top: 100px;
        @include mq(tbl){
          margin-top: 120px;
        }
      }
      p {
        @include text-default;
      }
      .gateway {
        margin-top: 30px;
        text-align: center;
        @include mq(tbl){
          margin-top: 60px;
        }
        img {
          width: 260px;
          @include mq(tbl){
            width: 300px;
          }
        }
      }
      dl {
        margin-top: 25px;
        border: 1px solid #CCC;
        padding: 0 14px;
        @include mq(tbl){
          margin-top: 30px;
          padding: 0 29px;
          display: flex;
          flex-wrap: wrap;
        }
        dt {
          text-align: center;
          color: $sub;
          @include fs(24,24);
          padding: 32px 0 14px;
          @include mq(tbl){
            @include fs(28,28);
            width: 72px;
            border-bottom: 1px solid #ccc;
            padding: 35px 0;
            &:last-of-type {
              border-bottom: none;
            }
          }
        }
        dd {
          @include text-default;
          padding-top: 0;
          padding-bottom: 25px;
          border-bottom: 1px solid #ccc;
          &:last-child {
            border-bottom: none;
          }
          @include mq(tbl){
            width: 508px;
            padding: 20px 40px 0 20px;
          }
        }
      }
    }
  }
}
.m_about_schedule {
  margin-top: 125px;
  background: center / cover no-repeat url(/images/about_05.jpg);
  padding: 85px 0 88px;
  @include mq(tbl){
    margin-top: 145px;
    padding: 115px 0 118px;
  }
  .inner {
    @include container;
    text-align: center;
    color: $white;
    h2 {
      @include fs(15,26);
      letter-spacing: .15em;
      @include mq(tbl){
        @include fs(18,28);
      }
      br {
        @include mq(tbl){
          display: none;
        }
      }
    }
    p {
      margin-top: 20px;
      @include mq(tbl){
        margin-top: 30px;
      }
      a {
        display: block;
        max-width: 292px;
        margin: 0 auto;
        border: 1px solid rgba(255,255,255,.5);
        color: $white;
        border-radius: 1px;
        padding: 14px;
        @include fs(14.4,25);
        letter-spacing: .2em;
        @include buttonhover;
        @include mq(tbl){
          max-width: 343px;
          @include fs(17,30);
          padding: 16px 73px;
          border-radius: 2px;
          max-width: none;
          display: inline-block;
        }
      }
    }
  }
}
.m_about_overview {
  margin-top: 65px;
  @include container(30px,narrow);
  @include mq(tbl){
    margin-top: 75px;
  }
  .inner {
    background-color: $white;
    padding: 60px 30px;
    @include mq(tbl){
      padding: 74px 112px 65px;
    }
    h2 {
      text-align: center;
      @include fs(19,19);
      letter-spacing: .2em;
      @include mq(tbl){
        @include fs(22,22);
      }
    }
    dl {
      margin-top: 36px;
      text-align: center;
      border-bottom: 1px solid #ccc;
      @include fs(10,24);
      letter-spacing: .1em;
      color: $main-light;
      @include mq(tbl){
        margin-top: 50px;
        @include fs(12,28);
        display: flex;
        flex-wrap: wrap;
        text-align: left;
      }
      dt {
        border-top: 1px solid #ccc;
        padding: 25px 0 0;
        @include mq(tbl){
          padding: 32px 0 32px 110px;
          width: 50%;
        }
      }
      dd {
        padding: 0 0 25px;
        font-weight: 300;
        @include mq(tbl){
          border-top: 1px solid #ccc;
          padding: 32px 0;
          width: 50%;
        }
        a {
          text-decoration: underline;
          @include mq(tbl){
            transition: color $transition-speed-fast $bezier;
            &:hover {
              color: $sub;
              svg {
                fill: $sub;
              }
            }
          }
          svg {
            width: 9px;
            fill: #666666;
            margin-left: 5px;
            transform: translateY(1px);
            transition: fill $transition-speed-fast $bezier;
          }
        }
      }
    }
  }
}
.m_schedule_cat {
  margin-top: 65px;
  @include mq(tbl){
    margin-top: 75px;
  }
  .inner {
    @include container;
    ul {
      display: flex;
      position: relative;
      &::before {
        @include pd;
        height: 100%;
        left: 50%;
        aspect-ratio: 34 / 51;
        transform: translateX(-50%);
        background: center / cover no-repeat url(/images/schedule_bg.svg);
        z-index: 1;
      }
      li {
        color: $white;
        @include fs(16,16);
        letter-spacing: .2em;
        text-align: center;
        padding: 19px 0;
        flex: 1;
        @include mq(tbl){
          @include fs(19,19);
          padding: 21px 0;
        }
        &:first-child {
          background-color: $sub;
        }
        &:last-child {
          background-color: #808080;
        }
        span {
          position: relative;
          z-index: 2;
        }
      }
    }
  }
}
.m_schedule_body {
  position: relative;
  @include mq(tbl){
    margin-top: 53px;
  }
  &:after {
    @include pd;
    width: 12px;
    height: 1px;
    background-color: #808080;
    left: 50%;
    transform: translateX(-50%);
    bottom: -17px;
  }
  .inner {
    @include container;
    position: relative;
    padding-bottom: 10px;
    @include mq(tbl){
      background: center top / 4px repeat-y url(/images/schedule_dot.svg);
    }
    &:before, &:after {
      @include pd;
      width: 4px;
      height: 4px;
      background-color: #808080;
      border-radius: 50%;
      left: 50%;
      transform: translateX(-50%);
      bottom: -17px;
      @include mq(tbl){
        content: none;
      }
    }
    &:after {
      bottom: -31px;
    }
    .archives {
      position: relative;
      @include mq(tbl){
        width: 50%;
      }
      &:before {
        @include pd;
        width: 4px;
        height: 4px;
        background-color: #808080;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
        top: -17px;
        @include mq(tbl){
          content: none;
        }
      }
      &.satellite {
        .unit {
          color: $sub;
          @include mq(tbl){
            padding-left: 88px;
          }
        }
      }
      &.test {
        @include mq(tbl){
          margin-left: auto;
        }
        .unit {
          @include mq(tbl){
            padding-right: 88px;
          }
          .title {
            border-color: #808080;
            @include mq(tbl){
              border-width: 1px 1px 0 0;
            }
            &:before {
              background-color: #808080;
              @include mq(tbl){
                right: auto;
                transform: translateX(0);
                left: -6px;
              }
            }
            .y {
              background-color: #808080;
              @include mq(tbl){
                right: 0;
                left: auto;
              }
            }
            .m {
              color: $main-light;
              @include mq(tbl){
                padding-left: 110px;
              }
            }
            h2 {
              @include mq(tbl){
                padding-left: 110px;
              }
            }
          }
          .text {
            @include mq(tbl){
              padding-left: 110px;
            }
          }
          .image {
            @include mq(tbl){
              padding-left: 110px;
            }
          }
        }
      }
      .unit {
        text-align: center;
        position: relative;
        margin-top: 54px;
        @include mq(tbl){
          padding-top: 6px;
          margin-bottom: 36px;
          margin-top: 0;
          text-align: left;
          min-height: 126px;
        }
        &:first-child {
          &:before, &:after {
            content: none;
          }
          .title {
            @include mq(tbl){
              border-width: 1px 0 0 0;
            }
          }
        }
        &:before, &:after {
          @include pd;
          width: 4px;
          height: 4px;
          background-color: #808080;
          border-radius: 50%;
          left: 50%;
          transform: translateX(-50%);
          top: -17px;
          @include mq(tbl){
            content: none;
          }
        }
        &:after {
          top: -31px;
        }
        .title {
          border: 1px solid $sub;
          border-bottom: none;
          position: relative;
          @include mq(tbl){
            border-width: 1px 0 0 1px;
            position: static;
            padding-right: 70px;
          }
          &:before {
            @include pd;
            width: 12px;
            height: 12px;
            background-color: $sub;
            border-radius: 50%;
            left: 50%;
            transform: translateX(-50%);
            top: -6px;
            @include mq(tbl){
              top: 0;
              right: -6px;
              transform: translateX(0);
              left: auto;
            }
          }
          .y {
            background-color: $sub;
            color: $white;
            font-family: $kin;
            @include fs(20,20);
            letter-spacing: .1em;
            padding: 8px;
            @include mq(tbl){
              position: absolute;
              @include fs(24,24);
              left: 0;
              top: 6px;
              width: 88px;
              text-align: center;
              padding-top: 35px;
              padding-bottom: 35px;
            }
          }
          .m {
            margin-top: 25px;
            font-family: $kin;
            @include fs(15,15);
            letter-spacing: .1em;
            padding: 0 15px;
            color: $sub;
            @include mq(tbl){
              @include fs(18,18);
              padding: 27px 0 0 40px;
              margin-top: 0;
            }
          }
          h2 {
            @include fs(16,30);
            letter-spacing: .125em;
            padding: 0 15px;
            margin-top: 2px;
            @include mq(tbl){
              @include fs(19,36);
              padding: 0 0 0 40px;
              margin-top: 4px;
            }
          }
        }
        .text {
          @include fs(10,17);
          letter-spacing: .1em;
          padding: 10px 15px 0;
          color: $main-light;
          @include mq(tbl){
            @include fs(12,20);
            padding: 0 70px 0 40px;
            margin-top: 15px;
          }
        }
        .image {
          text-align: center;
          margin-top: 35px;
          img {
            max-width: 440px;
            width: 100%;
          }
        }
      }
    }
  }
}