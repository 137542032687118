@import "variables";

@mixin pd {
  position: absolute;
  content: '';
  display: block;
}
@mixin mq($breakpoint: tbl) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin sp_pc {
  &.sp {
    @include mq(tbl) {
      display: none;
    }
  }
  &.pc {
    display: none;
    @include mq(tbl) {
      display: block;
    }
  }
}
@mixin container($sp:30px,$type:normal) {
  padding-left: $sp;
  padding-right: $sp;
  @include mq(pc) {
    padding-left: calc(50% - (#{$container} / 2));
    padding-right: calc(50% - (#{$container} / 2));
  }
  @if $type == narrow {
    @include mq(pc) {
      padding-left: calc(50% - (#{$container-narrow} / 2));
      padding-right: calc(50% - (#{$container-narrow} / 2));
    }
  }
}
@mixin button($size:medium) {
  display: inline-block;
  background-color: $main;
  color: $white !important;
  @include fs(16,28);
  padding: 6px 50px 6px 35px;
  border-radius: $radius-large;
  text-decoration: none !important;
  font-weight: $font-weight-bold;
  position: relative;
  &:before {
    @include pd;
    background: center / contain no-repeat url(#{$imagepath}arrow_right_white.svg);
    width: 16px;
    height: 16px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  @include mq(tbl) {
    @include hoverOp(.5);
    padding: 10px 50px 10px 35px;
  }
  @if $size == small {
    @include fs(12,16);
    padding: 7px 15px;
    border-radius: $radius-large;
    color: $black !important;
    background-color: $white;
    border: 1px solid #DBDBDB;
    &:before {
      content: none;
    }
    @include mq(tbl) {
      @include fs(14,18);
      padding: 7px 24px;
    }
  }
  @else if $size == large {
    border-radius: $radius-large;
    background-color: $white;
    color: $black !important;
    border: 1px solid $main;
    padding: 8px 35px;
    @include fs(14,24);
    &:before {
      content: none;
    }
    @include mq(tbl) {
      padding: 8px 35px;
      @include fs(16,28);
    }
  }
  @else if $size == apply {
    background-color: $red;
    padding: 12px 35px;
    min-width: 340px;
    @include fs(20,30);
    @include mq(tbl) {
      @include hoverOp(.5);
      padding: 20px 35px;
    }
  }
}
@mixin hoverOp($op:0.7) {
  transition: opacity $transition-speed-fast $bezier;
  &:hover {
    opacity: $op;
  }
}
@mixin fs($fts, $lin: 14) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}
@mixin slickdots($width:4px,$height:4px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 7px;
    @include mq(tbl){
      margin: 0 11px;
    }
    button {
      border: none;
      background-color: #CCCCCC;
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      border-radius: 50%;
      @include mq(tbl){
        height: 6px;
        width: 6px;
      }
    }
    &.slick-active {
      button {
        background-color: #808080;
      }
    }
  }
}
@mixin buttonhover {
  transition: all $transition-speed-fast $bezier;
  &:hover {
    @include mq(tbl){
      border-color: $sub;
      color: $white;
      background-color: $sub;
    }
  }
}

@mixin title-default {
  @include fs(18.5,37);
  letter-spacing: .2em;
  @include mq(tbl){
    @include fs(22,44);
  }
}
@mixin text-default {
  @include fs(11,25);
  letter-spacing: .1em;
  padding-top: 2em;
  font-weight: 300;
  &:first-child {
    padding-top: 0;
  }
  @include mq(tbl){
    @include fs(13,30);
  }
}
@mixin lnav {
  text-align: center;
  &.slick-disabled {
    opacity: 0;
  }
  a,span {
    cursor: pointer;
    position: relative;
    padding-left: 34px;
    padding-right: 34px;
    @include fs(12,23);
    letter-spacing: .1em;
    @include mq(tbl) {
      @include fs(15,28);
      padding-left: 43px;
      padding-right: 43px;
      transition: color $transition-speed-fast $bezier;
      &:hover {
        color: $sub;
        &:after {
          opacity: 0;
        }
      }
    }
    &:after,&:before {
      @include pd;
      width: 9px;
      height: 17px;
      top: 50%;
      transform: translateY(-50%);
      @include mq(tbl) {
        width: 11px;
        height: 21px;
      }
    }
  }
}
@mixin prev {
  @include lnav;
  @include mq(tbl) {
    position: absolute;
    left: 0;
  }
  a, span {
    &:after {
      background: center / contain no-repeat url(/images/prev.svg);
      z-index: 2;
      left: 0;
      transition: opacity $transition-speed-fast $bezier;
    }
    &:before {
      background: center / contain no-repeat url(/images/prev_b.svg);
      z-index: 1;
      left: 0;
    }
  }
}
@mixin next {
  @include lnav;
  @include mq(tbl) {
    position: absolute;
    right: 0;
  }
  a, span {
    &:after {
      background: center / contain no-repeat url(/images/next.svg);
      z-index: 2;
      right: 0;
      transition: opacity $transition-speed-fast $bezier;
    }
    &:before {
      background: center / contain no-repeat url(/images/next_b.svg);
      z-index: 1;
      right: 0;
    }
  }
}