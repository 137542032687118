$container: 1278px;
$container-narrow: 1100px;

$main: #1c1c1c;
$white: #fff;
$red: #FF4D4D;
$black: $main;
$main-dark:$main;
$main-light:#4d4d4d;
$sub:#C9A201;
$sub-dark:#C9A201;
$third:#EB8B61;
$lightgray: #F2F2F2;
$bg: #F1F1F1;

$link-default:$main-light;
$body-text-color: $black;

$radius-xsmall:0px;
$radius-small:0px;
$radius-default: 0px;
$radius-large: 0px;

// transitions settings

$bezier: cubic-bezier(.25,.1,.25,1);
$transition-speed: .5s;
$transition-speed-fast: .3s;

// font settings

$font-family-default: "Noto Serif JP", serif;
$m: "Noto Serif JP", serif;
$b: "Noto Serif JP", serif;
$roboto: 'Roboto',sans-serif;
$bbb: "Noto Sans JP", sans-serif;
$kin: "Noto Sans JP", sans-serif;
$sys:"Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;

$font-weight-normal: 400;
$font-weight-bold: 700;

// debug settings

$responsive-debug-mode: false;

// header settings

$header-height: 70px;
$header-height-pc: 103px;

// store icon settings

$header-store:true;
$header-store-width:$header-height;

// menu button settings

$menu-bar-width: 23px;

$imagepath: '/images/';

$breakpoints: (
  'min': 'print, screen and (max-width: 374px)',
  'sp': 'print, screen and (min-width: 375px)',
  'tb': 'print, screen and (min-width: 600px)',
  'tbl': 'print, screen and (min-width: 1000px)',
  'pc': 'print, screen and (min-width: 1363px)',
) !default;
