@charset "UTF-8";
@import "variables";
@import "mixins";

@mixin topbutton {
  display: block;
  text-align: center;
  max-width: 170px;
  margin: 0 auto;
  border: 1px solid #CCCCCC;
  border-radius: 1px;
  padding: 7px;
  @include fs(10,17);
  letter-spacing: .25em;
  @include buttonhover;
  font-weight: 300;
  @include mq(tbl){
    max-width: 200px;
    @include fs(12,21);
    padding: 10px;
    border-radius: 2px;
  }
}
@mixin topheading {
  @include fs(18,18);
  letter-spacing: .5em;
  text-align: center;
  @include mq(tbl){
    @include fs(21,21);
  }
}
header.top {
  background: center / cover url(/images/top_mv.jpg);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  @media (orientation: landscape) and (max-height: 500px) {
    background-image: url(/images/top_mv_pc.jpg);
  }
  @include mq(tbl){
    background-image: url(/images/top_mv_pc.jpg);
    position: relative;
  }
  .inner {
    #logo {
      display: none;
    }
    @include mq(tbl){
      #gnav {
        padding-top: 100px;
        justify-content: center;
        .logo {
          display: none;
        }
        .left {
          width: 50%;
        }
        .right {
          width: 50%;
        }
        .main {
          li {
            margin: 0 10px;
            a {
              color: #D9D9D9;
              &:before {
                background-color: rgba(217,217,217,.3);
              }
              &:hover {
                color: $sub;
                &:before {
                  background-color: $sub;
                }
              }
            }
          }
        }
        .contact {
          a {
            color: #D9D9D9;
            border-color: rgba(217,217,217,.3);
          }
        }
        .lang {
          color: #D9D9D9;
          li {
            a {
              color: #D9D9D9;
            }
          }
        }
      }
    }
  }
  .top_mv {
    height: 100%;
    @include mq(tbl){
      position: absolute;
      width: 100%;
      top: 0;
    }
    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @media (orientation: landscape) and (max-height: 500px) {
        top: 40%;
      }
      img {
        width: 62px;
        @media (orientation: landscape) and (max-height: 500px) {
          width: 52px;
        }
        @include mq(tbl){
          width: 74px;
        }
        @include mq(pc){
          width: 89px;
          @media (max-height: 700px) {
            width: 70px;
          }
        }
      }
    }
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,0);
      padding-top: 191px;
      @media (orientation: landscape) and (max-height: 500px) {
        padding-top: 90px;
      }
      @include mq(tbl){
        top: auto;
        padding-top: 0;
        bottom: 105px;
        @media (max-height: 700px) {
          bottom: 75px;
        }
      }
      img {
        width: 250px;
        max-width: none;
        @media (orientation: landscape) and (max-height: 500px) {
          width: 200px;
        }
        @include mq(tbl){
          width: 280px;
        }
        @include mq(pc){
          width: 330px;
          @media (max-height: 700px) {
            width: 280px;
          }
        }
      }
    }
  }
}
.m_banner {
  margin-top: 30px;
  @include container(0px);
  @include mq(tbl){
    margin-top: 60px;
  }
  .inner {
    position: relative;
    border-bottom: 1px solid #ccc;
    padding-bottom: 30px;
    @include mq(tbl){
      padding-bottom: 40px;
    }
    &:before, &:after {
      @include pd;
      width: 55px;
      height: 82px;
      top: 0;
      z-index: 2;
      @include mq(tbl){
        width: 200px;
        height: 131px;
      }
    }
    &:before {
      left: 0;
      background: linear-gradient(to right, rgba(241,241,241,1) 0%,rgba(241,241,241,0) 100%); 
    }
    &:after {
      right: 0;
      background: linear-gradient(to left, rgba(241,241,241,1) 0%,rgba(241,241,241,0) 100%); 
    }
  }
  .slick-dots {
    margin-top: 20px;
    @include slickdots;
    @include mq(tbl){
      margin-top: 36px;
    }
  }
  #m_banner_slide {
    .unit {
      padding: 0 12px;
      @include mq(tbl){
        padding: 0 20px;
      }
      a {
        @include hoverOp;
        display: block;
      }
      img {
        width: 250px;
        @include mq(tbl){
          width: 400px;
        }
      }
    }
  }
}
.m_top_news_list {
  padding-top: 86px;
  @include mq(tbl) {
    padding-top: 100px;
  }
  .inner {
    @include container;
    @include mq(tbl) {
      padding-left: calc(50% - 440px);
      padding-right: calc(50% - 440px);
    }
    h1 {
      @include topheading;
    }
    ul {
      margin-top: 34px;
      border-top: 1px solid #CCCCCC;
      border-bottom: 1px solid #CCCCCC;
      @include mq(tbl) {
        margin-top: 43px;
      }
      li {
        padding: 18px 17px 15px;
        border-top: 1px solid #CCCCCC;
        @include mq(tbl) {
          padding: 20px 0;
          text-align: left;
          display: flex;
        }
        &:first-child {
          border-top: 0;
        }
        .date {
          font-family: $kin;
          @include fs(12.7,25);
          letter-spacing: .025em;
          color: #666;
          @include mq(tbl) {
            @include fs(15,15);
            width: 27%;
            padding-left: 20px;
            padding-top: 3px;
          }
        }
        h2 {
          margin-top: 5px;
          @include fs(11,19);
          letter-spacing: .25em;
          font-weight: 300;
          @include mq(tbl) {
            @include fs(13,22);
            width: 73%;
            margin-top: 0;
          }
          a {
            @include mq(tbl){
              transition: color $transition-speed-fast $bezier;
              &:hover {
                color: $sub;
              }
            }
          }
        }
      }
    }
    .button {
      margin-top: 30px;
      @include mq(tbl){
        margin-top: 34px;
      }
      a {
        @include topbutton;
      }
    }
  }
}
.m_top_sns {
  margin-top: 120px;
  @include mq(tbl) {
    margin-top: 150px;
  }
  .inner {
    @include container;
    @include mq(tbl) {
      padding-left: calc(50% - 440px);
      padding-right: calc(50% - 440px);
    }
    h1 {
      @include topheading;
    }
    .unit {
      border-top: 1px solid #ccc;
      margin-top: 94px;
      &:first-of-type {
        margin-top: 70px;
        @include mq(tbl) {
          margin-top: 80px;
        }
      }
      @include mq(tbl) {
        margin-top: 80px;
        border-top: 0;
        display: grid;
        grid-template-columns: 23% 72%;
        grid-column-gap: 5%;
        justify-items: stretch;
        align-items: start;
      }
      .title {
        padding: 28px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mq(tbl) {
          border-top: 1px solid #ccc;
          display: block;
          padding: 45px 0 0;
        }
        h2 {
          font-family: $roboto;
          @include fs(14.45,25);
          letter-spacing: .15em;
          @include mq(tbl) {
            margin-top: 19px;
            @include fs(17,28);
          }
        }
        .button {
          flex:1;
          @include mq(tbl) {
            margin-top: 19px;
          }
          a {
            @include topbutton;
            margin-right: 0;
          }
        }
      }
      .body {
        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          @include mq(tbl) {
            gap: 10px;
          }
          li {
            width: calc(50% - 4px);
            position: relative;
            @include mq(tbl) {
              width: calc(33.3% - 7px);
            }
            a {
              display: block;
              height: 100%;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              aspect-ratio: 1 / 1;
              overflow: hidden;
              @include mq(tbl) {
                &:hover {
                  .caption {
                    opacity: 1;
                  }
                }
              }
              .caption {
                height: 100%;
                background-color: rgba(0,0,0,.6);
                color: $white;
                @include fs(13,22);
                text-align: center;
                display:flex;
                align-items: center;
                justify-content: center;
                padding: 20px;
                opacity: 0;
                transition: opacity $transition-speed-fast $bezier;
                font-family: $sys;
                word-break: break-all;
              }
            }
          }
        }
      }
    }
  }
}
.m_top_media {
  margin-top: 150px;
  text-align: center;
  @include mq(tbl) {
    margin-top: 130px;
  }
  .inner {
    @include container;
    h1 {
      @include topheading;
    }
    .lead {
      margin-top: 50px;
      @include fs(11,19);
      letter-spacing: .25em;
      font-weight: 300;
      @include mq(tbl) {
        margin-top: 60px;
        @include fs(13,22);
      }
    }
    .logo {
      margin-top: 27px;
      @include mq(tbl) {
        margin-top: 55px;
      }
      img {
        max-width: 838px;
        width: 100%;
      }
    }
  }
}
.m_top_nav {
  margin-top: 120px;
  text-align: center;
  @include mq(tbl) {
    margin-top: 150px;
  }
  .inner {
    @include container;
    h1 {
      @include topheading;
      letter-spacing: .4em;
    }
    ul {
      margin-top: 30px;
      @include mq(tbl) {
        margin-top: 35px;
        display: flex;
        justify-content: space-between;
      }
      li {
        margin-top: 44px;
        @include mq(tbl) {
          margin-top: 0;
          width: 31.1%;
        }
        &:first-child {
          margin-top: 0;
        }
        a {
          display: block;
          border-radius: 1px;
          background-color: $white;
          aspect-ratio: 315 / 451;
          max-width: 480px;
          display: grid;
          grid-template-rows: 86% 14%;
          justify-items: stretch;
          align-items: stretch;
          margin: 0 auto;
          transition: all $transition-speed-fast $bezier;
          @include mq(tbl) {
            border-radius: 2px;
            &:hover {
              background-color: $sub;
              color: white;
              .photo {
                img {
                  transform: scale(1.1);
                }
              }
            }
          }
          .photo {
            overflow: hidden;
            border-radius: 1px 1px 0 0;
            @include mq(tbl) {
              border-radius: 2px 2px 0 0;
            }
            img {
              transition: transform $transition-speed-fast $bezier;
              object-fit: cover;
            }
          }
          h2 {
            align-self: center;
            @include fs(12,20);
            letter-spacing: .25em;
            font-family: "Zen Old Mincho", system-ui;
            @include mq(tbl) {
              @include fs(15,25);
            }
          }
        }
      }
    }
  }
}