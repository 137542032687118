@charset "UTF-8";
@import "variables";
@import "mixins";

.m_kuyo_body {
  .inner {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(pc) {
      padding-left: calc(50% - 605px);
      padding-right: calc(50% - 605px);
    }
    .unit {
      margin-top: 90px;
      @include mq(tbl){
        margin-top: 198px;
        display: flex;
        align-items: center;
        gap: 6%;
      }
      .image {
        text-align: center;
        @include mq(tbl){
          width: 45%;
        }
        img {
          max-width: 480px;
          width: 100%;
        }
      }
      .text {
        margin-top: 50px;
        @include mq(tbl){
          margin-top: 0;
          width: 49%;
          padding: 0 15% 0 20px;
        }
        h2 {
          @include title-default;
          text-align: center;
          @include mq(tbl){
            margin-top: 0;
            text-align: left;
          }
        }
        p {
          @include text-default;
          &.note {
            @include fs(10,23);
            padding-top: 4em;
            @include mq(tbl){
              @include fs(12,28);
            }
          }
        }
      }
    }
  }
}
.m_kuyo_type {
  margin-top: 100px;
  @include mq(tbl){
    margin-top: 170px;
  }
  .inner {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(tbl) {
      padding-left: calc(50% - 440px);
      padding-right: calc(50% - 440px);
    }
    .parent {
      margin-top: 60px;
      @include mq(tbl){
        margin-top: 100px;
      }
      h2 {
        margin-bottom: 20px;
        @include fs(16,32);
        letter-spacing: .2em;
        @include mq(tbl){
          @include fs(19,36);
          margin-bottom: 45px;
        }
        &:first-of-type {
          margin-top: 0;
        }
        + .unit {
          border-top: 1px solid #CCCCCC;
        }
      }
      .unit {
        border-bottom: 1px solid #CCCCCC;
        padding: 30px 15px 25px;
        @include mq(tbl){
          padding: 55px 35px 45px;
        }
        h3 {
          @include fs(16,32);
          letter-spacing: .2em;
          @include mq(tbl){
            @include fs(19,36);
          }
        }
        dl {
          @include text-default;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 15px;
          @include mq(tb){
            flex-wrap: nowrap;
            margin-bottom: 25px;
          }
          dt {
            padding:3px 0 3px 0;
            border: 1px solid #CCCCCC;
            width: 100px;
            text-align: center;
            margin-bottom: 10px;
            @include mq(tb){
              width: auto;
              padding: 3px 20px 3px 21px;
              margin-bottom: 0;
            }
          }
          dd {
            width: calc(100% - 100px);
            margin-bottom: 10px;
            padding-left: 16px;
            @include mq(tb){
              width: auto;
              margin-bottom: 0;
              padding-right: 16px;
            }
            @include mq(tbl){
              width: 110px;
            }
          }
        }
        p, ul {
          @include text-default;
          padding: 0;
        }
        ul {
          li {
            padding-left: 1em;
            &::before {
              content: '\30FB';
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }
          }
        }
      }
      .button {
        margin-top: 35px;
        @include mq(tbl){
          margin-top: 70px;
        }
        a {
          display: block;
          max-width: 518px;
          margin: 0 auto;
          text-align: center;
          border: 1px solid $main-light;
          background-color: $main-light;
          color: $white;
          border-radius: 1px;
          padding: 14px;
          @include fs(14.4,25);
          letter-spacing: .2em;
          transition: all $transition-speed-fast $bezier;
          &:hover {
            @include mq(tbl){
              color: $white;
              background-color: $sub;
              border-color: $sub;
            }
          }
          @include mq(tbl){
            @include fs(17,30);
            padding: 16px 73px;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
.m_kuyo_hideyoshi {
  background: center center / cover no-repeat url(/images/kuyo_bg.jpg);
  margin-top: 100px;
  @include mq(tbl){
    margin-top: 140px;
    background-image: url(/images/kuyo_bg_pc.jpg);
  }
  .inner {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(pc) {
      padding-left: calc(50% - 605px);
      padding-right: calc(50% - 605px);
    }
    color: $white;
    padding-top: 90px;
    padding-bottom: 70px;
    @include mq(tbl){
      padding-top: 163px;
      padding-bottom: 163px;
    }
    .unit {
      @include mq(tbl){
        display: flex;
        align-items: center;
        gap: 6%;
      }
      .image {
        text-align: center;
        @include mq(tbl){
          width: 45%;
        }
        img {
          width: 100%;
        }
      }
      .text {
        margin-top: 50px;
        color: $white;
        @include mq(tbl){
          margin-top: 0;
          width: 49%;
          padding: 0 15% 0 20px;
        }
        h2 {
          @include title-default;
          text-align: center;
          @include mq(tbl){
            margin-top: 0;
            text-align: left;
          }
        }
        p {
          @include text-default;
          &.note {
            @include fs(10,23);
            padding-top: 4em;
          }
        }
      }
    }
  }
}