@charset "UTF-8";
@import "variables";
@import "mixins";

.m_policy_body {
  padding-top: 50px;
  @include mq(tbl) {
    padding-top: 150px;
  }
  .inner {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(tbl) {
      padding-left: calc(50% - 400px);
      padding-right: calc(50% - 400px);
    }
    @include mq(pc) {
      padding-left: calc(50% - 440px);
      padding-right: calc(50% - 440px);
    }
    dl {
      padding: 55px 0;
      border-top: 1px solid #ccc;
      @include mq(tbl) {
        padding: 70px 0 65px;
        display: flex;
      }
      &:last-child {
        border-bottom: 1px solid #ccc;
      }
      dt {
        @include mq(tbl) {
          width: 27%;
          padding: 0 30px 0 20px;
        }
        h2 {
          @include fs(12.7,25);
          letter-spacing: .1em;
          @include mq(tbl) {
            @include fs(15,30);
          }
        }
      }
      dd {
        margin-top: 30px;
        @include fs(10,23);
        letter-spacing: .1em;
        @include mq(tbl) {
          width: 73%;
          margin-top: 0;
          @include fs(12,28);
          padding-right: 20px;
        }
        p, ul, ol {
          padding-bottom: 3em;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}