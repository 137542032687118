@charset "UTF-8";
@import "variables";
@import "mixins";

.m_title {
  position: absolute;
  width: 100%;
  z-index: 3;
  &.static {
    position: static;
    @include mq(tbl){
      position: absolute;
    }
    .inner {
      h1 {
        color: $main;
        &:before {
          background-color: #ccc;
        }
      }
    }
  }
  .inner {
    @include container;
    h1 {
      @include fs(16,28);
      letter-spacing: .25em;
      padding-top: 46px;
      writing-mode: vertical-rl;
      position: relative;
      color: $white;
      @include mq(tbl){
        @include fs(20,28);
      }
      @include mq(pc){
        transform: translateX(-100%);
      }
      &:before {
        @include pd;
        width: 1px;
        height: 34px;
        top: 0;
        left: 50%;
        background-color: $white;
      }
    }
  }
}
.m_mv {
  &.schedule {
    .inner {
      img {
        object-fit: cover;
        height: 297px;
        @include mq(tbl){
          height: 350px;
        }
      }
    }
  }
  .inner {
    position: relative;
    img {
      @include sp_pc;
      width: 100%;
    }
    h2 {
      position: absolute;
      top: 50%;
      z-index: 2;
      @include fs(20,20);
      letter-spacing: .2em;
      color: $white;
      width: 100%;
      text-align: center;
      @include mq(tbl){
        @include fs(24,24);
      }
    }
  }
}
.m_contact {
  background-color: #E6E6E6;
  padding: 100px 0 98px;
  position: relative;
  margin-top: 150px;
  @include mq(tbl){
    padding: 115px 0;
    margin-top: 170px;
  }
  &:before {
    @include pd;
    width: 1px;
    background-color: #ccc;
    height: 60px;
    left: 50%;
    top: 0;
    @include mq(tbl){
      height: 70px;
    }
  }
  .inner {
    @include container;
    text-align: center;
    h1 {
      @include fs(17,29);
      letter-spacing: .15em;
      @include mq(tbl){
        @include fs(20,35);
      }
      br {
        @include mq(tbl){
          display: none;
        }
      }
    }
    .button {
      margin-top: 22px;
      @include mq(tbl){
        margin-top: 27px;
      }
      a {
        display: block;
        max-width: 292px;
        margin: 0 auto;
        border: 1px solid #CCCCCC;
        border-radius: 1px;
        padding: 14px;
        @include fs(14.4,25);
        letter-spacing: .2em;
        @include buttonhover;
        @include mq(tbl){
          max-width: 343px;
          @include fs(17,30);
          padding: 16px 73px;
          border-radius: 2px;
          max-width: none;
          display: inline-block;
        }
      }
    }
    .tel {
      margin-top: 20px;
      @include fs(20,20);
      letter-spacing: .125em;
      @include mq(tbl){
        margin-top: 24px;
        @include fs(24,24);
      }
      img {
        width: 30px;
        margin-right: 8px;
        vertical-align: -1px;
        @include mq(tbl){
          width: 34px;
        }
      }
    }
  }
}
.m_uc {
  padding-bottom: 150px;
  margin-top: -150px;
  @include mq(tbl){
    padding-top: 150px;
    padding-bottom: 300px;
    margin-top: 0;
  }
  .inner {
    @include container;
    text-align: center;
    @include fs(13,30);
  }
}