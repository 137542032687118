@charset "UTF-8";
@import "variables";
@import "mixins";

footer {
  background-color: $main-light;
  padding: 80px 0 32px;
  color: #e8e8e8;
  position: relative;
  @include mq(tbl) {
    padding: 93px 0 67px;
  }
  a {
    color: #e8e8e8;
    transition: color $transition-speed-fast $bezier;
    @include mq(tbl) {
      &:hover {
        color: #ffffff;
      }
    }
  }
  .inner {
    @include container(30px,narrow);
    .logo {
      text-align: center;
      img {
        @include sp_pc;
        width: 100px;
        @include mq(tbl) {
          width: 120px;
        }
      }
    }
    .footnav {
      @include mq(tbl) {
        margin-top: 60px;
        padding-right: 16%;
        display: flex;
      }
      .unit {
        display: flex;
        margin-top: 50px;
        @include mq(tbl) {
          display: block;
          margin-top: 0;
          &:first-of-type {
            margin-right: 8%;
          }
          &:nth-of-type(2) {
            margin-right: 10%;
          }
          &:last-of-type {
            margin-left: auto;
            text-align: center;
          }
        }
        h2 {
          width: 120px;
          @include fs(10,10);
          font-family: $roboto;
          letter-spacing: .3em;
          padding-top: 4px;
          @include mq(tbl) {
            @include fs(12,12);
            padding-top: 0;
            width: auto;
          }
        }
        ul {
          flex: 1;
          @include mq(tbl) {
            margin-top: 20px;
          }
          &.sns {
            display: flex;
            transform: translateY(-14px);
            @include mq(tbl) {
              transform: translateY(0);
            }
            li {
              margin-top: 0;
              margin-left: 18px;
              @include mq(tbl) {
                transform: translateY(0);
                justify-content: center;
                margin-left: 13px;
              }
              a {
                opacity: .6;
                @include mq(tbl) {
                  @include hoverOp(1);
                }
                img {
                  width: 43px;
                  @include mq(tbl) {
                    width: 30px;
                  }
                }
              }
            }
          }
          li {
            @include fs(13.5,20);
            letter-spacing: .25em;
            margin-top: 1.75em;
            @include mq(tbl) {
              @include fs(14,21);
              padding-top: 0;
              margin-top: 1.5em;
            }
            &:first-child {
              margin: 0;
            }
            a {
              display: block;
              position: relative;
            }
          }
        }
      }
    }
    .bottom {
      text-align: center;
      margin-top: 30px;
      @include mq(tbl) {
        text-align: left;
        margin-top: 90px;
        display: flex;
      }
      .contact {
        display: inline-block;
        a {
          display: block;
          border:1px solid rgba(217,217,217,.3);
          padding: 13px 36px;
          border-radius: 1px;
          @include fs(13.5,14);
          letter-spacing: .2em;
          background-color: transparent;
          @include buttonhover;
          transition: color $transition-speed-fast $bezier;
          @include mq(tbl){
            border:none;
            padding: 0;
            border-radius: 0;
            font-family: $bbb;
            @include fs(11,11);
            letter-spacing: .25em;
            &:hover {
              background-color: transparent;
              color: #f0f0f0;
            }
          }
        }
      }
      .lang {
        margin-top: 25px;
        // display: flex;
        justify-content: center;
        font-family: $roboto;
        @include fs(10,10);
        letter-spacing: .5em;
        display: none;
        @include mq(tbl){
          display: none;
        }
        li {
          &:after {
            content: '/';
            margin: 0 .75em;
          }
          &:last-child {
            &:after {
              content: none;
            }
          }
        }
      }
      .util {
        margin-top: 75px;
        @include fs(11,11);
        letter-spacing: .25em;
        font-family: $bbb;
        @include mq(tbl){
          margin-top: 0;
          display: flex;
        }
        li {
          margin-top: 28px;
          @include mq(tbl){
            margin-top: 0;
            margin-left: 42px;
          }
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .copyright {
        margin-top: 55px;
        @include fs(9,9);
        font-family: $bbb;
        letter-spacing: .2em;
        transform: scale(.9);
        @include mq(tbl){
          margin-top: 0;
          margin-left: auto;
          @include fs(10,10);
          transform: scale(1);
        }
      }
    }
  }
}

#pagetop {
  position: absolute;
  top: -55px;
  right: 30px;
  z-index: 9999;
  cursor: pointer;
  opacity: 1;
  @include mq(tbl) {
    top: -66px;
    right: 30px;
  }
  @include mq(pc) {
    right: 50%;
    transform:translateX($container / 2);
  }
  a {
    @include mq(tbl) {
      &:hover {
        img {
          opacity: 1;
        }
      }
    }
    img {
      width: 42px;
      height: 42px;
      opacity: .8;
      transition: opacity .5s $bezier;
      @include mq(tbl) {
        width: 50px;
        height: 50px;
      }
    }
  }
}
