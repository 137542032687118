@charset "UTF-8";
@import "variables";
@import "mixins";

.m_contact_body {
  padding-top: 120px;
  padding-bottom: 150px;
  @include mq(tbl) {
    padding-top: 150px;
  }
  .wpcf7-spinner {
    display: none;
  }
  .inner {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(tbl) {
      padding-left: calc(50% - 400px);
      padding-right: calc(50% - 400px);
    }
    @include mq(pc) {
      padding-left: calc(50% - 440px);
      padding-right: calc(50% - 440px);
    }
    .lead {
      padding-bottom: 35px;
      @include mq(tbl) {
        padding-bottom: 40px;
      }
      p {
        text-align: center;
        @include fs(12.75,25);
        letter-spacing: .025em;
        @include mq(tbl) {
          @include fs(15,30);
        }
      }
    }
    .unit {
      padding: 35px 0 28px;
      border-top: 1px solid #ccc;
      @include mq(tbl) {
        padding: 30px 0;
      }
      &:last-child {
        border-bottom: 1px solid #ccc;
      }
      dl {
        @include mq(tbl) {
          display: flex;
        }
        + dl {
          margin-top: 35px;
        }
        dt {
          @include fs(13.6,24);
          letter-spacing: .2em;
          @include mq(tbl) {
            @include fs(16,28);
            width: 27%;
            padding: 10px 20px 0 0;
          }
          span {
            color: $red;
            font-family: $kin;
            @include fs(12.75,17);
            letter-spacing: .1em;
            margin-left: .5em;
            @include mq(tbl) {
              @include fs(15,20);
            }
          }
        }
        dd {
          margin-top: 3px;
          @include fs(13.6,24);
          @include mq(tbl) {
            @include fs(16,28);
            width: 73%;
          }
          input[type="text"],input[type="email"],input[type="tel"],textarea {
            background-color: $white;
            border: none;
            appearance: none;
            font-family: $sys;
            @include fs(16,24);
            padding: 8px;
            width: 100%;
            border-radius: 0;
            @include mq(tbl) {
              padding: 10px;
            }
            &.wpcf7-not-valid {
              border-bottom: 2px solid $red;
            }
            &.wpcf7c-conf {
              background-color: transparent;
            }
          }
          textarea {
            height: 190px;
            &.wpcf7-not-valid {
              border-bottom: 2px solid $red;
            }
            &.wpcf7c-conf {
              background-color: transparent;
              background-image: none;
            }
          }
          select {
            background-image: url(/images/select.svg);
            background-position: left 12px center;
            background-size: 5px auto;
            background-color: $white;
            background-repeat: no-repeat;
            border: none;
            font-family: $sys;
            width: 100%;
            height: 40px;
            padding: 0 10px 0 30px;
            border-radius: 0;
            appearance: none;
            color: inherit;
            @include fs(16,24);
            &.wpcf7-not-valid {
              border-bottom: 2px solid $red;
            }
            &.wpcf7c-conf {
              background-color: transparent;
              background-image: none;
              padding-left: 0;
            }
          }
          label {
            display: flex;
            align-items: center;
            @include fs(12.75,25);
            letter-spacing: .025em;
            @include mq(tbl) {
              @include fs(15,20);
              margin-top: 20px;
            }
            input {
              margin-right: 10px;
              &.wpcf7-not-valid {
                border: 2px solid $red;
              }
            }
            a {
              text-decoration: underline;
              @include mq(tbl){
                transition: color $transition-speed-fast $bezier;
                &:hover {
                  color: $sub;
                }
              }
            }
          }
          .wpcf7-not-valid-tip {
            color: $red;
            margin-top: 10px;
            font-family: $kin;
            @include fs(12.75,17);
            letter-spacing: .1em;
            @include mq(tbl) {
              @include fs(15,20);
              display: block;
            }
          }
          ul {
            @include mq(tbl) {
              display: flex;
            }
            li {
              margin-top: 20px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              @include mq(tbl) {
                margin-top: 0;
                min-width: 255px;
              }
              &:first-child {
                margin-top: 0;
              }
              .name-wrap {
                width: calc(100% - 30px);
                @include mq(tbl) {
                  width: 178px;
                }
                input {
                  @include mq(tbl) {
                    width: 100%;
                  }
                }
              }
              i {
                display: inline-block;
                color: #B0B0B0;
                width: 30px;
              }
              .wpcf7-form-control-wrap {
                
              }
            }
          }
        }
      }
    }
    .button {
      margin-top: 10px;
      text-align: center;
      display: flex;
      flex-direction: column-reverse;
      @include mq(tbl) {
        margin-top: 30px;
        gap: 94px;
        flex-direction: row;
        justify-content: center;
      }
      button,
      input[type="submit"],
      input[type="button"] {
        text-align: center;
        background-color: $main-light;
        color: $white;
        @include fs(14.45,25);
        letter-spacing: .2em;
        appearance: none;
        padding: 13px;
        border: none;
        width: 100%;
        font-family: $sys;
        border-radius: 1px;
        @include buttonhover;
        cursor: pointer;
        @include mq(tbl) {
          width: 343px;
          @include fs(17,29);
          border-radius: 3px;
          padding: 17px;
        }
        &:disabled {
          opacity: .3;
          cursor: not-allowed;
        }
      }
      input[type="button"] {
        margin-top: 30px;
        color: $main;
        border: 1px solid #ccc;
        background-color: transparent;
        @include mq(tbl) {
          margin-top: 0;
        }
      }
      .wpcf7c-btn-back {
        margin-bottom: 30px;
        @include mq(tbl) {
          margin-bottom: 0;
          margin-right: 30px;
        }
      }
      .ajax-loader {
        display: none;
      }
    }
  }
}
.wpcf7-response-output {
  display: none !important;
}
.m_contact_thanks {
  padding-top: 80px;
  padding-bottom: 150px;
  @include mq(tbl){
    padding-top: 150px;
    padding-bottom: 300px;
  }
  .inner {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(tbl) {
      padding-left: calc(50% - 320px);
      padding-right: calc(50% - 320px);
    }
    h2 {
      @include fs(20,37);
      letter-spacing: .2em;
      text-align: center;
      @include mq(tbl){
        @include fs(24,44);
      }
    }
    .text {
      margin-top: 30px;
      @include mq(tbl){
        margin-top: 40px;
      }
      p {
        @include text-default;
        text-align: center;
      }
    }
  }
}