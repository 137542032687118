@charset "UTF-8";
@import "variables";
@import "mixins";

.m_faq_body {
  padding-top: 100px;
  @include mq(tbl) {
    padding-top: 150px;
  }
  .inner {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(tbl) {
      padding-left: calc(50% - 400px);
      padding-right: calc(50% - 400px);
    }
    @include mq(pc) {
      padding-left: calc(50% - 440px);
      padding-right: calc(50% - 440px);
    }
    dl {
      padding: 38px 0 30px;
      border-top: 1px solid #ccc;
      @include mq(tbl) {
        padding: 42px 0 42px;
      }
      &:last-child {
        border-bottom: 1px solid #ccc;
      }
      dt {
        position: relative;
        cursor: pointer;
        @include mq(tbl) {
          display: flex;
          padding: 0 80px;
          &:hover {
            h2 {
              color: $sub;
            }
          }
        }
        @include mq(pc) {
          padding: 0 120px;
        }
        &:before, &:after {
          @include pd;
          background-color: #9C9C9C;
          right: 11px;
          top: 14px;
          @include mq(tbl) {
            top: 17px;
          }
        }
        &:before {
          width: 23px;
          height: 1px;
          top: 25px;
          @include mq(tbl) {
            width: 25px;
            top: 29px;
          }
        }
        &:after {
          height: 23px;
          width: 1px;
          right: 22px;
          transition: transform $transition-speed-fast $bezier;
          @include mq(tbl) {
            height: 25px;
            right: 23px;
          }
        }
        &.active {
          &:after {
            transform: scaleY(0);
          }
        }
        i {
          background-color: $white;
          border-radius: 50%;
          width: 51px;
          height: 51px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $roboto;
          @include fs(18.7,18.7);
          text-indent: .1em;
          letter-spacing: .1em;
          @include mq(tbl) {
            width: 60px;
            height: 60px;
            @include fs(22,22);
            margin-right: 18px;
          }
        }
        h2 {
          @include fs(18.7,37);
          letter-spacing: .2em;
          margin-top: 12px;
          transition: color $transition-speed-fast $bezier;
          @include mq(tbl) {
            @include fs(22,44);
            margin-top: 10px;
          }
        }
      }
      dd {
        padding: 22px 0 0;
        @include fs(10,23);
        letter-spacing: .1em;
        display: none;
        @include mq(tbl) {
          padding: 40px 80px 10px;
          @include fs(12,28);
        }
        @include mq(pc) {
          padding: 40px 120px 10px;
        }
        p {
          padding-bottom: 2em;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}