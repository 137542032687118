@charset "UTF-8";
@import "variables";
@import "mixins";

.m_partner_body {
  padding-top: 80px;
  padding-bottom: 50px;
  @include mq(tbl){
    padding-top: 65px;
    padding-bottom: 100px;
  }
  .inner {
    @include container(30px,narrow);
    h2 {
      @include fs(20,37);
      letter-spacing: .2em;
      text-align: center;
      @include mq(tbl){
        @include fs(24,44);
      }
    }
    .text {
      margin-top: 30px;
      @include mq(tbl){
        margin-top: 40px;
      }
      p {
        @include text-default;
        text-align: center;
      }
    }
    .button {
      margin-top: 85px;
      @include mq(tbl){
        margin-top: 95px;
      }
      a {
        display: block;
        max-width: 518px;
        margin: 0 auto;
        text-align: center;
        border: 1px solid $main-light;
        background-color: $main-light;
        color: $white;
        border-radius: 1px;
        padding: 14px;
        @include fs(14.4,25);
        letter-spacing: .2em;
        transition: all $transition-speed-fast $bezier;
        &:hover {
          @include mq(tbl){
            color: $white;
            background-color: $sub;
            border-color: $sub;
          }
        }
        @include mq(tbl){
          @include fs(17,30);
          padding: 16px 73px;
          border-radius: 2px;
        }
      }
    }
  }
}