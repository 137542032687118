@charset "UTF-8";
@import "variables";
@import "mixins";

.m_prayer_body {
  .inner {
    padding-left: 30px;
    padding-right: 30px;
    @include mq(pc) {
      padding-left: calc(50% - 570px);
      padding-right: calc(50% - 570px);
    }
    .unit {
      margin-top: 120px;
      @include mq(tbl){
        margin-top: 292px;
        display: flex;
      }
      &:first-child {
        margin-top: 90px;
        @include mq(tbl){
          margin-top: 186px;
        }
      }
      &:nth-of-type(2n){
        flex-direction: row-reverse;
        .text {
          @include mq(tbl){
            padding: 70px 9% 0 13%;
          }
        }
      }
      .image {
        text-align: center;
        @include mq(tbl){
          width: 42%;
        }
        img {
          max-width: 480px;
          width: 100%;
        }
      }
      .text {
        @include mq(tbl){
          width: 58%;
          padding: 70px 13% 0 9%;
        }
        h2 {
          @include title-default;
          text-align: center;
          margin-top: 30px;
          @include mq(tbl){
            margin-top: 0;
            text-align: left;
          }
        }
        p {
          @include text-default;
        }
        dl {
          @include text-default;
          display: flex;
          align-items: center;
          dt {
            padding:3px 18px 3px 20px;
            border: 1px solid #CCCCCC;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
.m_prayer_cases {
  background: center top / contain no-repeat url(/images/prayer_bg.jpg) #4675A9;
  margin-top: 35px;
  @include mq(tbl){
    margin-top: 105px;
    background-position: center center;
    background-size: cover;
  }
  .inner {
    @include container;
    color: $white;
    padding-top: 74px;
    padding-bottom: 80px;
    text-align: center;
    @include mq(tbl){
      padding-top: 90px;
      padding-bottom: 100px;
    }
    h2 {
      @include title-default;
    }
    .main {
      margin-top: 70px;
      @include mq(tbl){
        margin-top: 80px;
        display: flex;
        gap: 68px 42px;
        flex-wrap: wrap;
      }
      .unit {
        margin-top: 53px;
        @include mq(tbl){
          margin-top: 0;
          width: calc(50% - 21px);
        }
        h3 {
          background-color: rgba(255,255,255,.9);
          color: #0B234F;
          padding: 7px 15px;
          border-radius: 30px;
          @include fs(16,30);
          letter-spacing: .2em;
          filter: drop-shadow(0 0 4px rgba(0, 30, 140, .2));
          @include mq(tbl){
            width: 398px;
            margin: 0 auto;
            @include fs(19,36);
            padding: 8px 45px;
          }
        }
        .message {
          margin-top: 20px;
          @include fs(16,30);
          letter-spacing: .2em;
          @include mq(tbl){
            @include fs(19,36);
          }
        }
        .name {
          margin-top: 15px;
          @include fs(11,22);
          letter-spacing: .1em;
          @include mq(tbl){
            margin-top: 10px;
            @include fs(13,26);
          }
        }
      }
    }
    .sub {
      margin-top: 55px;
      @include mq(tbl){
        margin-top: 105px;
        display: flex;
        gap: 0 42px;
        flex-wrap: wrap;
      }
      .unit {
        border-bottom: 1px solid rgba(223,231,240,.4);
        padding: 30px 0 27px;
        @include mq(tbl){
          width: calc(33.3% - 28px);
          border-bottom: 1px solid rgba(223,231,240,.4);
          padding: 40px 0 33px;
        }
        &:first-child {
          border-top: 1px solid rgba(223,231,240,.4);
        }
        &:nth-child(2),&:nth-child(3) {
          @include mq(tbl){
            border-top: 1px solid rgba(223,231,240,.4);
          }
        }
        .message {
          @include fs(12.7,22);
          letter-spacing: .175em;
          min-height: 60px;
          @include mq(tbl){
            min-height: auto;
            @include fs(15,26);
            letter-spacing: .2em;
          }
        }
        .name {
          margin-top: 10px;
          @include fs(11,22);
          letter-spacing: .1em;
          @include mq(tbl){
            @include fs(13,26);
          }
        }
      }
    }
  }
}