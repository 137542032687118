@charset "UTF-8";
@import "variables";
@import "mixins";

header {
  .inner {
    #logo {
      padding: 27px 0 26px 33px;
      @include mq(tbl) {
        display: none;
      }
      a {
        display: block;
        img {
          width: 143px;
        }
      }
    }
    #gnav {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1001;
      text-align: center;
      @include fs(13, 13);
      letter-spacing: 0.2em;
      color: #d9d9d9;
      //background: linear-gradient(to bottom,  hsl(230,76%,13%) 0%,hsl(213,52%,36%) 100%);
      background: center center / cover no-repeat url(/images/menu_bg.jpg);
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      transform: translateX(100%);
      transition: transform $transition-speed $bezier;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 33px 15px 27px;
      @include mq(tbl) {
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        background: transparent;
        transform: translateX(0);
        overflow: visible;
        padding: 53px 20px 89px;
        max-width: 1360px;
        margin: 0 auto;
        color: $main-light;
      }
      @include mq(pc) {
        padding-left: 0;
        padding-right: 0;
      }
      a {
        color: #d9d9d9;
        @include mq(tbl) {
          color: $main-light;
        }
      }
      &.show {
        transform: translateX(0);
      }
      .right {
        @include mq(tbl) {
          width: calc(50% - 50px);
          display: flex;
          align-items: center;
        }
      }
      .left {
        @include mq(tbl) {
          width: calc(50% - 50px);
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .main {
          li {
            &:first-child {
              a {
                &:before {
                  width: 142px;
                  @include mq(tbl) {
                    width: 100%;
                  }
                }
                &:hover,
                &.current {
                  &:before {
                    @include mq(tbl) {
                      width: 30px;
                      min-width: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .main {
        @include mq(tbl) {
          display: flex;
          align-items: center;
        }
        li {
          position: relative;
          font-family: "Zen Old Mincho", system-ui;
          font-weight: 500;
          @include mq(tbl) {
            margin: 0 5px;
          }
          &.press {
            a {
              letter-spacing: 0.175em;
            }
          }
          a {
            display: block;
            padding: 23px 0 12px;
            font-size: 1.35rem;
            letter-spacing: 0.2em;
            text-align: center;
            position: relative;
            @include mq(tbl) {
              padding: 0 0 13px;
              line-height: 1.3;
              min-width: 70px;
              font-size: 1.3rem;
              transition: color $transition-speed $bezier;
            }
            @include mq(pc) {
              min-width: 100px;
            }
            &:before {
              @include pd;
              width: 102px;
              height: 1px;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              background-color: rgba(217, 217, 217, 0.3);
              @include mq(tbl) {
                width: 100%;
                background-color: #ccc;
                transition: all $transition-speed $bezier;
              }
              @include mq(pc) {
                min-width: 102px;
                width: auto;
              }
            }
            span {
              display: inline-block;
              @include mq(tbl) {
                padding: 0;
              }
              br {
                display: none;
                @include mq(tbl) {
                  display: inline;
                }
                @include mq(pc) {
                  display: none;
                }
              }
              i {
                @include mq(tbl) {
                  display: none;
                }
                @include mq(pc) {
                  display: inline;
                }
              }
            }
            &:hover,
            &.current {
              @include mq(tbl) {
                color: $sub;
              }
              &:before {
                @include mq(tbl) {
                  width: 30px;
                  min-width: 30px;
                  background-color: $sub;
                }
              }
            }
          }
        }
      }
      .contact {
        margin-top: 35px;
        display: inline-block;
        font-family: "Zen Old Mincho", system-ui;
        font-weight: 500;
        @include mq(tbl) {
          margin-top: 0;
          margin-left: auto;
          line-height: 1.3;
        }
        @include mq(pc) {
          transform: translateY(-4px);
        }
        a {
          display: block;
          border: 1px solid rgba(217, 217, 217, 0.3);
          padding: 13px 36px;
          border-radius: 1px;
          background-color: transparent;
          @include buttonhover;
          @include mq(tbl) {
            border-color: #b3b3b3;
            padding: 8px 25px;
            border-radius: 2px;
          }
          @include mq(pc) {
            padding: 10px 30px;
          }
        }
        br {
          display: none;
          @include mq(tbl) {
            display: inline;
          }
          @include mq(pc) {
            display: none;
          }
        }
      }
      .logo {
        display: none;
        @include mq(tbl) {
          display: block;
          width: 100px;
          text-align: center;
        }
        a {
          @include mq(tbl) {
            &:hover {
              svg {
                fill: $sub;
              }
            }
          }
          svg {
            width: 54px;
            fill: $main-light;
            transition: fill $transition-speed-fast $bezier;
          }
        }
      }
      .lang {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        font-family: $roboto;
        @include fs(10, 10);
        letter-spacing: 0.5em;
        display: none;
        @include mq(tbl) {
          position: absolute;
          right: 20px;
          top: 53px;
          margin-top: 0;
        }
        @include mq(pc) {
          right: 0;
        }
        li {
          &:after {
            content: "/";
            margin: 0 0.75em;
          }
          &:last-child {
            &:after {
              content: none;
            }
          }
          a {
            @include mq(tbl) {
              @include hoverOp(0.5);
            }
          }
        }
      }
      .policy {
        margin-top: 35px;
        @include fs(11, 11);
        font-family: $bbb;
        @include mq(tbl) {
          display: none;
        }
      }
    }
  }
}

#menuButton {
  position: absolute;
  right: 4px;
  top: 0;
  width: 66px;
  height: 66px;
  cursor: pointer;
  z-index: 1002;
  @include mq(tbl) {
    display: none;
  }
  span {
    background-color: #808080;
    width: $menu-bar-width;
    height: 1px;
    border-radius: 0px;
    position: absolute;
    left: (66px - $menu-bar-width) / 2;
    &.top {
      top: 26px;
      transition: transform $transition-speed-fast $bezier,
        background $transition-speed-fast $bezier;
    }
    &.middle,
    &.back {
      top: 32px;
      transform-origin: center;
      transition: transform $transition-speed-fast $bezier,
        background $transition-speed-fast $bezier;
    }
    &.bottom {
      top: 38px;
      transition: transform $transition-speed-fast $bezier,
        background $transition-speed-fast $bezier;
    }
  }
  &.show {
    span {
      background-color: $white;
      &.top {
        transform: translateX(50%);
        background-color: transparent;
      }
      &.bottom {
        transform: translateX(-50%);
        background-color: transparent;
      }
      &.middle {
        transform: rotate(30deg) scaleX(1);
        transition: transform $transition-speed-fast $bezier
          $transition-speed-fast;
      }
      &.back {
        transform: rotate(-30deg) scaleX(1);
      }
    }
  }
}
