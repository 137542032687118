@charset "UTF-8";
@import "variables";
@import "mixins";

.m_news_list {
  @include mq(tbl) {
    padding-top: 150px;
  }
  .inner {
    @include container;
    ul {
      margin-top: 34px;
      border-top: 1px solid #CCCCCC;
      @include mq(tbl) {
        margin-left: 200px;
        padding-right: 200px;
        margin-top: 0;
        border-bottom: 1px solid #CCCCCC;
      }
      li {
        padding: 36px 0;
        text-align: center;
        border-top: 1px solid #CCCCCC;
        @include mq(tbl) {
          padding: 50px 0;
          text-align: left;
          display: flex;
        }
        &:first-child {
          border-top: 0;
        }
        .date {
          font-family: $kin;
          @include fs(12.7,25);
          letter-spacing: .025em;
          color: #666;
          @include mq(tbl) {
            @include fs(15,15);
            width: 27%;
            padding-left: 20px;
            padding-top: 10px;
          }
        }
        h2 {
          @include fs(16,36);
          letter-spacing: .2em;
          @include mq(tbl) {
            @include fs(19,36);
            width: 73%;
          }
          a {
            text-decoration: underline;
            @include mq(tbl){
              transition: color $transition-speed-fast $bezier;
              &:hover {
                color: $sub;
              }
            }
          }
        }
      }
    }
  }
}
.m_news_nav {
  @include container;
  .inner {
    border-top: 1px solid #ccc;
    padding-top: 6px;
    position: relative;
    @include mq(tbl) {
      padding: 35px 17px 0;
      border-top: 0;
      margin-left: 200px;
    }
    .wp-pagenavi {
      @include mq(tbl) {
        display: flex;
        justify-content: center;
        margin-left: 100px;
        margin-right: 100px;
        gap: 0 30px;
      }
      .pages {
        display: none;
      }
      .prev {
        margin: 30px 0 0 0;
        @include prev;
        @include mq(tbl) {
          margin-top: 5px;
        }
      }
      .next {
        margin: 30px 0 0 0;
        @include next;
        @include mq(tbl) {
          margin-top: 5px;
        }
      }
      .page, .current, .extend {
        display: none;
        @include mq(tbl) {
          display: inline-block;
          height: 40px;
          min-width: 40px;
          border-radius: 50%;
          max-width: 0 15px;
          @include fs(17,17);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .page {
        transition: color $transition-speed-fast $bezier;
        &:hover {
          color: $sub;
        }
      }
      .current {
        background-color: $white;
      }
    }
  }
}
.m_news_body {
  @include mq(tbl) {
    padding-top: 150px;
  }
  .inner {
    @include container;
    article {
      margin-top: 34px;
      border-top: 1px solid #CCCCCC;
      @include mq(tbl) {
        margin-left: 200px;
        padding-right: 120px;
        margin-top: 0;
        border-bottom: 1px solid #CCCCCC;
      }
      @include mq(pc) {
        padding-right: 200px;
      }
      .title {
        padding: 45px 0 40px;
        text-align: center;
        @include mq(tbl) {
          display: flex;
          padding: 50px 0 ;
          text-align: left;
        }
        .date {
          font-family: $kin;
          @include fs(12.7,25);
          letter-spacing: .025em;
          color: #666;
          @include mq(tbl) {
            @include fs(15,15);
            width: 27%;
            padding-left: 20px;
            padding-top: 10px;
          }
        }
        h1 {
          @include fs(16,36);
          letter-spacing: .2em;
          @include mq(tbl) {
            @include fs(19,36);
            width: 73%;
          }
          a {
            text-decoration: underline;
            @include mq(tbl){
              transition: color $transition-speed-fast $bezier;
              &:hover {
                color: $sub;
              }
            }
          }
        }
      }
      .contents {
        @include mq(tbl){
          padding-left: 27%;
        }
        p, ul, ol {
          padding-bottom: 3em;
          @include fs(10,23);
          letter-spacing: .1em;
          @include mq(tbl){
            @include fs(12,28);
          }
          img {
            max-width: 640px;
            width: 100%;
            display: block;
            margin: 10px auto;
            float: none;
          }
          &:first-child {
            img {
              margin-top: 0;
            }
          }
        }
        ul {
          list-style: disc;
          margin-left: 20px;
        }
        ol {
          list-style: decimal;
          margin-left: 20px;
        }
        a {
          text-decoration: underline;
          @include mq(tbl){
            transition: color $transition-speed-fast $bezier;
            &:hover {
              color: $sub;
            }
          }
        }
      }
      .sns {
        padding-bottom: 30px;
        @include mq(tbl){
          padding-bottom: 35px;
          padding-left: 27%;
        }
        ul {
          display: flex;
          justify-content: center;
          gap: 0 10px;
          @include mq(tbl){
            gap: 0 12px;
            justify-content: flex-end;
          }
          li {
            position: relative;
            a {
              @include mq(tbl){
                @include hoverOp();
              }
              img {
                width: 25px;
                @include mq(tbl){
                  width: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.m_news_nav_detail {
  @include container;
  .inner {
    border-top: 1px solid #ccc;
    padding-top: 6px;
    position: relative;
    @include mq(tbl) {
      padding: 35px 17px 0;
      border-top: 0;
      margin-left: 200px;
    }
    .prev {
      margin: 30px 0 0 0;
      @include prev;
      @include mq(tbl) {
        margin-top: 0;
      }
    }
    .next {
      margin: 30px 0 0 0;
      @include next;
      @include mq(tbl) {
        margin-top: 0;
      }
    }
    .back {
      @include lnav;
      text-decoration: underline;
      margin-top: 30px;
      @include mq(tbl) {
        margin-left: 100px;
        margin-right: 100px;
        margin-top: 0;
        z-index: 2;
      }
      &:after {
        content: none;
      }
    }
  }
}
.url-copied {
  display: none;
}
.url-copied i {
  background: $sub;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  @include fs(11,11);
  color: $white;
  padding: 2px 8px;
  border-radius: 3px;
}